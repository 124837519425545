import { useState } from "react"
import { StyleProp, Text, TouchableOpacity, View, ViewStyle } from "react-native"
import Button from "~/ui/button/Button"

const style:StyleProp<ViewStyle> = {
  // backgroundColor:'blue',
  // borderColor:'#D3D3D3',
  // borderStyle:'solid',
  // borderWidth:1,
  // borderRadius:5,
  // paddingHorizontal:10,
  // paddingVertical:10,
  width:'100%'
}

export default function SelectMenu({children,label}:{label:string,children:React.ReactNode}) {
  const [isOpen,setIsOpen] = useState(false)
  return (
    <View style={{position:'relative',backgroundColor:''}} >


      <Button
        label="Bank toevoegen"
        onPress={() => setIsOpen(true)}
      />

      {isOpen &&
        <View style={{alignItems:'flex-start'}} >
        {children}
      </View>}

    </View>
  )
}

