import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';
import LoginScreen from '~/screens/LoginScreen/LoginScreen';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import UserProvider,{UserContext} from '~/context/UserContext/UserContext';
import { useContext } from 'react';
import HomeScreen from './packages/screens/HomeScreen/HomeScreen';
import {useFirebase} from '~/utils/firebase'
import Header from '~/components/Header/Header';
import MailScreen from '~/screens/MailScreen/MailScreen';
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen';
import InvoiceScreen from '~/screens/InvoiceScreen/InvoiceScreen';
import BankScreen from '~/screens/BankScreen/BankScreen';
import InvoicesScreen from '~/screens/InvoiceScreen/InvoicesScreen';

const Stack = createNativeStackNavigator();

function Navigation() {
  const {user} = useContext(UserContext)
  const linking = {
    prefixes: ['aimdirect.nl'],
    screens: {
      mail: 'mail',
    },
  };

  return (

    <NavigationContainer
    
    linking={linking}
    fallback={<LoadingScreen />}
    >
      <Stack.Navigator
      screenOptions={{header:Header}}
      >
        {
          (typeof user == 'undefined')
          ? <Stack.Screen name="login" component={LoginScreen} />
          : (
            <>
              <Stack.Screen name="bank" component={BankScreen} />
              <Stack.Screen name="home" component={HomeScreen} />
              <Stack.Screen name="invoices" component={InvoicesScreen} />
              <Stack.Screen name="invoice" component={InvoiceScreen} />
              <Stack.Screen name="mail" component={MailScreen} />
            </>
            )
        }
      </Stack.Navigator>
    </NavigationContainer>

  )

}
export default function App() {
  useFirebase()
  return (
    <UserProvider>
      <Navigation />
    </UserProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
