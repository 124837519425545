import { useContext, useState } from "react";
import { FlatList, Text, TouchableOpacity, View } from "react-native";
import Container from "~/components/Misc/Container";
import Divider from "~/components/Misc/Divider";
import ListContainer from "~/components/Misc/ListContainer";
import { UserContext } from "~/context/UserContext/UserContext";
import { InvoiceType } from "~/types/invoice";


const InvoiceListItem = (props:{item:InvoiceType,onPress:() => void}) => {
  return (
    <>
    <TouchableOpacity
      onPress={props.onPress}
      style={{height:50,display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between'}} >
      <Text>{props.item.data.number}</Text>
      <Text>{props.item.data.title}</Text>
    </TouchableOpacity>
    <Divider />
    </>
  )
}

export default function InvoicesScreen({navigation}){


  const {invoices} = useContext(UserContext)
  return(
    <Container>
      {/* <Text>InvoiceListScreen</Text> */}
      <ListContainer
        label='Invoices'
      
      
      >
      <FlatList
        data={invoices}
        renderItem={({item}) => <InvoiceListItem onPress={() => navigation.navigate('invoice',{id:item.id})} item={item} />}
        />
      </ListContainer>
    </Container>
  )
}


