import { Control, Controller, FieldValues } from "react-hook-form";
import { TextInput } from "react-native";


interface InputProps{
  control: Control<any, any> | undefined
  name:string

}
export default function Input(props: InputProps) {
  return (
    <Controller
    name={props.name}
    control={props.control}
    render={({field:{value,onChange}}) => (
      <TextInput
        value={value}
        onChangeText={onChange}
      />
    )}
    />
  )
}