import { useInvoices,InvoiceType } from "~/context/UserContext/utils/useInvoices";
import { useUser } from "~/context/UserContext/utils/useUser";
import { createContext, useContext } from "react";
import { userType } from "~/types/user";
import { usePayments } from "~/context/UserContext/utils/usePayments";
import { PaymentItem } from "~/types/payment";
import { Text, View } from "react-native";
import { organisationType } from "~/types/organisation";
import { useOrganisation } from "~/context/UserContext/utils/useOrganisation";

interface UserContextProps {
  user:userType | undefined
  invoices:InvoiceType[]
  payments:PaymentItem[]
  organisation:organisationType | undefined
}
export const UserContext = createContext( {}  as UserContextProps)



export default function UserProvider({children}:{children:React.ReactNode}){
  const user = useUser()
  const invoices = useInvoices(user?.data?.currentOrganisation)
  const payments = usePayments(user?.data?.currentOrganisation)
  const organisation = useOrganisation(user?.data?.currentOrganisation)



  return (
    <UserContext.Provider value={{
      user,
      invoices,
      payments,
      organisation
      }}>
      {children}
    </UserContext.Provider>
  )
}

const useUserContext = () => useContext(UserContext)
export {useUserContext}