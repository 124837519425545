import { getAuth, onAuthStateChanged, User } from "firebase/auth"
import { doc, getFirestore, onSnapshot } from "firebase/firestore"
import { useEffect, useState } from "react"
import { userType } from "~/types/user"

export const useUser = () => {
  const [userUID,setUserUID] = useState<undefined | string>(undefined)
  const [user,setUser] = useState<userType | undefined>(undefined)
  useEffect(() => {
    const unsub = onAuthStateChanged(
      getAuth(),
      (user) => {
        setUserUID(( user == null) ? undefined : user!.uid)
      }
      )
      return () => unsub()
  },[])

  useEffect(() => {
    if(typeof userUID == 'undefined' )return;
    const unsub = onSnapshot(
      doc(getFirestore(),`users/${userUID}`),(snapshot) => {
        setUser({id:snapshot.id,data:snapshot.data()} as userType)
    })
    return () => unsub()
  },[userUID])
  return user
}
