import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Text, View } from "react-native";
import InvoiceView from "~/components/Invoice/InvoiceView";
import { useUserContext } from "~/context/UserContext/UserContext";
import { InvoiceType } from "~/types/invoice";
import Input from "~/ui/input/Input";


export default function InvoiceScreen({route}){
  const {control} = useForm()
  const {invoices} = useUserContext()

  const [invoiceData,setInvoiceData] = useState<InvoiceType | undefined>(undefined)

  useEffect(() => {
    const {id} = route.params

    setInvoiceData(invoices.find((invoice) => invoice.id === id))
  },[route.params])

  return (
    <View style={{display:'flex',flexDirection:'row',height:'100%'}} >
      <View style={{flex:5}} >
        {/* <InvoiceForm /> */}
        {JSON.stringify(invoiceData,null,2)}
      </View>
      <View style={{flex:3,backgroundColor:'green'}} >
        <InvoiceView
          invoice={invoiceData}
          />
      </View>
    </View>
  )
  
}




const InvoiceForm = () => {
  
  return (
    <View>
      <Text>InvoiceForm</Text>

    </View>
  )
}