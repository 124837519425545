import { FlatList, Text, TouchableOpacity, View } from "react-native";
import * as fs from 'fs'

import Button from "~/ui/button/Button";
import readline from 'readline'
import { getFunctions, httpsCallable } from "firebase/functions";
import { useNavigation } from "@react-navigation/native";
import { gmail_v1, google } from "googleapis";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "~/context/UserContext/UserContext";
import { collection, getFirestore, limit, onSnapshot, query } from "firebase/firestore";
import base64 from 'react-native-base64'
import { Buffer } from "buffer";
import {MailMessage} from '~/types/email'
// import { WebView } from 'react-native-webview';

function b64DecodeUnicode(str:string) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(atob(str).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
}

export default function MailScreen({route}){
  const {user} = useContext(UserContext)
  useEffect(() => {
    const code = route?.params?.code
    if(code?.length == 0) return;
    console.log('code',code);
    httpsCallable(
      getFunctions(),
      'mail-addCode',
      )({code,userID:user?.id})
  },[route])
  const navigation = useNavigation()
  const callBackend = async() => {
    const response = await httpsCallable(
      getFunctions(),
      'mail-loginGmail',
      )()
      window.location.href = response.data.authUrl
  }

  const [emails,setEmails] = useState<MailMessage[]>()

  const [selectedEmailID,setSelectedEmailID] = useState<string>('')
  const [selectedEmailAccountID,setSelectedEmailAccountID] = useState<string>('')
  useEffect(() => {
    const asyncEmails = async() => {
      await httpsCallable(
        getFunctions(),
        'mail-listMails',
        )({userID:user?.id})
    }
    asyncEmails()
  },[selectedEmailAccountID])
  const [emailAccounts,setEmailAccounts] = useState<{id:string,data:any}[]>([])

  useEffect(() => {
    if(selectedEmailAccountID?.length == 0) return;
    const unsub = onSnapshot(
      query(
        collection(getFirestore(),`users/${user?.uid}/Mails/${selectedEmailAccountID}/messages`),
        limit(10)
      ),
      (snapshots) => {
        console.log('snapshots',snapshots.docs.length)
        setEmails(snapshots.docs.map(doc => ({id:doc.id,data:doc.data() as MailMessage['data']})))
      } 
    )
    return () => unsub()
  },[selectedEmailAccountID])

  useEffect(() => {
    const unsub = onSnapshot(
      collection(getFirestore(),`users/${user?.uid}/Mails`),
      (snapshots) => {
        console.log('snapshots',snapshots.docs.length)
        setEmailAccounts(snapshots.docs.map(doc => ({id:doc.id,data:doc.data()})))
      } 
    )
    return () => unsub()
  },[])

  const selectedEmail = emails?.find(email => email.id==selectedEmailID)



  return (
    <View style={{width:'100%',display:'flex',flexDirection:"row",height:'100%'}}>
      <View style={{flex:1,backgroundColor:'white'}} >


      <Button
        label="Add gmail"
        onPress={() => callBackend()}
      />
      {/* <Button
        label="List labels"
        onPress={() => listLabels()}
      /> */}
      {/* <Text>{JSON.stringify(emailAccounts)}</Text> */}

      {emailAccounts.map((account) => {
        return (
        <TouchableOpacity onPress={() => setSelectedEmailAccountID(account.id)} >
          <Text>{JSON.stringify(account.id,null,2)}</Text>
        </TouchableOpacity>
          )
      })
      }
      </View>

      <View style={{flex:2,backgroundColor:'red'}} >

      <FlatList
        data={emails}
        renderItem={({item}) => <EmailHeaderItem  onPress={() => setSelectedEmailID(item.id)}  item={item} />}
        />

      </View>

      <View
        style={{flex:3}}
      >
        {selectedEmail ?
        <EmailItem item={selectedEmail} />
        :
        <Text>No mails found</Text>
        }
      </View>
      
    </View>
  )
}

function EmailHeaderItem({onPress,item}:{item:MailMessage,onPress:() => void}){
  return (
    <TouchableOpacity onPress={onPress} style={{height:50}} >
      <Text>{item.data.subject}</Text>
    </TouchableOpacity>
  )
}


function EmailItem({item}:{item:MailMessage}){


  return (
    <View>
      <View style={{display:'flex',flexDirection:'row',height:75,backgroundColor:'green'}} >
        <Text style={{margin:5}} >this is a Payment</Text>
        <Text style={{margin:5}} >arcive email</Text>
        <Text style={{margin:5}} >assign to:</Text>
        <Text style={{margin:5}} >Reply</Text>

      </View>
      {Boolean(item?.data?.originalData?.gmail.payload?.body?.data?.length) && <div dangerouslySetInnerHTML={{ __html: Buffer.from(item?.data?.originalData?.gmail.payload?.body?.data,'base64').toString('utf8') }} />}
      {
        item?.data?.originalData?.gmail.payload?.parts?.map((part) => {
          return (

          <View>
            <div dangerouslySetInnerHTML={{ __html: Buffer.from(part?.body?.data,'base64').toString('utf8') }} />
            {/* <Text>{JSON.stringify(part.body?.data,null,2)}</Text> */}
            {/* <Text>{JSON.stringify(part.body?.data,null,2)}</Text> */}
            <Text>testing</Text>
          </View>
            )
        })
      }
      {/* {JSON.stringify(.map(part => part.body?.data),null,2)} */}
      {/* {Boolean(item?.data?.originalData?.gmail.payload?.parts) && <div dangerouslySetInnerHTML={{ __html: Buffer.from(item?.data?.originalData?.gmail.payload?.body?.data,'base64').toString('utf8') }} />} */}
    </View>
  )
}