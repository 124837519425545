import { forwardRef, useImperativeHandle, useRef } from "react"
import { ScrollView, StyleSheet, View } from "react-native"
import ViewShot from "react-native-view-shot"
import Footer from "./components/Footer"
import Header from "./components/Header"
import Products from "./components/Products"
import * as FileSystem from 'expo-file-system'
import { documentTypes } from "~/types/document"
import { InvoiceType } from "~/types/invoice"
import { organisationType } from "~/types/organisation"
import { QuotationType } from "~/types/quotation"
import { RelationType } from "~/types/relation"
import { useUserContext } from "~/context/UserContext/UserContext"


const styles = StyleSheet.create({
  pdf_container:{
    display:'flex',
    justifyContent:'center',
    width:'100%'
  },
  pdf:{
    display:'flex',
    flexDirection:'column',
    width:'90%',
    aspectRatio:1/1.4142,
    backgroundColor:'red',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.20,
    shadowRadius: 5,
    elevation: 2,
  },
  pdf_product_row:{
    display:'flex',
    flexDirection:'row',
    width:'100%',
    height:20
  },
})

interface Props{
  invoice:InvoiceType | QuotationType,
  organisation:organisationType,
  relation:RelationType
  documentType: documentTypes
}

const ViewInvoice = forwardRef(
  ({invoice,relation,documentType}:Props,ref) => {
    const {organisation} = useUserContext()



  const viewShot = useRef<ViewShot[]>([])
  useImperativeHandle(ref, () => ({
    async generatePNG():Promise<string[]> {
      let list:string[] = [];
      for(const view of viewShot.current){
        const uri = await view.capture!()
        list.push( await FileSystem.readAsStringAsync(uri,{encoding:FileSystem.EncodingType.Base64}) )
      }
      return list
    },
  }))


// header allowed 6 products
  const maxProductsOnHeader = 4
  const allowedProductsPerPage = 9
  const totalPages = ():number => {
    if(!invoice?.data?.products?.length) return 1
    else if(invoice?.data?.products?.length <= maxProductsOnHeader) return 1
    return Math.ceil((invoice?.data?.products?.length-5)/allowedProductsPerPage)+1
  }


  const productBatch = ():any[] => {
    return new Array(totalPages()).fill(0).map((item,index) => {
        if(!invoice?.data?.products?.length) return []
        else if(index==0) return invoice?.data?.products.slice(0,(invoice?.data?.products?.length < maxProductsOnHeader ? invoice?.data?.products?.length : maxProductsOnHeader)) // max 5 products on first page
        else if(index==1) return invoice?.data?.products.slice(maxProductsOnHeader,maxProductsOnHeader+allowedProductsPerPage) // start from index 5
        return invoice?.data?.products.slice(maxProductsOnHeader+((index-1)*allowedProductsPerPage),maxProductsOnHeader+(index*allowedProductsPerPage))
    })
  }


  return (
    <View style={styles.pdf_container} >
      <ScrollView
      contentContainerStyle={{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        flexGrow: 1,
        flexDirection: 'column',
      }} >
          
        {productBatch().map( (p,index) => (
          <ViewShot
          options={{ format: "png", quality: 0.9 }}
          // @ts-ignore
          ref={el => viewShot!.current![index]! = el}
          key={`page-${index+1}`}
          style={{
            marginVertical:10,
            display:'flex',
            flexDirection:'column',
            width:'90%',
            aspectRatio:1/1.4142,
            backgroundColor:'white',
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: 1,
            },
            shadowOpacity: 0.20,
            shadowRadius: 5,
            elevation: 2,
          }} >
            {index==0 ? <Header key={`header-${index+1}`} documentType={documentType} invoice={invoice} organisation={organisation} relation={relation} /> : null }
            <Products document={invoice} key={`product-component-${index+1}`} products={p} />
            <Footer organisation={organisation} documentType={documentType} document={invoice} key={`footer-component-${index+1}`} products={invoice?.data?.products ?? []} num={index+1} totalNum={totalPages()} />
          </ViewShot>
        ))}
      </ScrollView>
    </View>
  )
})

export default ViewInvoice






