import { StyleProp, View, ViewStyle } from "react-native";


interface DividerProps{
  style?:StyleProp<ViewStyle>
}

const style:StyleProp<ViewStyle>  = {
  height:1,
  backgroundColor:'rgba(0,0,0,0.10)'
}

export default function Divider(props:DividerProps){
  return (
    <View style={[style,props.style]} />
  )
}