import { StyleProp, Text, TouchableOpacity, ViewStyle } from "react-native";


const style:StyleProp<ViewStyle> = {
  backgroundColor:'rgba(0,0,0,0.10)',
  width:30,
  height:30,
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  borderRadius:5
}
export default function CloseButton({ onPress }: { onPress: () => void }) {
  return (
    <TouchableOpacity style={style} >
      <Text>X</Text>
    </TouchableOpacity>
  )
}