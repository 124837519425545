import Button from "~/ui/button/Button";
import Input from "~/ui/input/Input";
import { useForm } from "react-hook-form";
import { Text, View } from "react-native";
import {getAuth, signInWithEmailAndPassword} from 'firebase/auth'


const LoginScreen = () => {

  const {control,handleSubmit} = useForm<{email:string,password:string}>()
  const onSubmit = handleSubmit((values) => {
    console.log(values)
    signInWithEmailAndPassword(
      getAuth(),
      values.email,
      values.password
      )
    
  })


  return (
    <View>

      <Input
        control={control}
        name="email"
      />

      <Input
        control={control}
        name="password"
      />

      <Button
        label="Login"
        onPress={onSubmit}
      />

    </View>
  )
}

export default LoginScreen;