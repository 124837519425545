import { StyleProp, Text, View, ViewStyle } from "react-native";
import Divider from "~/components/Misc/Divider";


const style:StyleProp<ViewStyle> = {
  display:'flex',
  justifyContent:'center',
  width:'100%',
  alignItems:'center',
  flexDirection:'column',
  padding:10
}

const containerStyle:StyleProp<ViewStyle> = {
  width:'95%',
  padding:20,
  backgroundColor:'white',
  borderRadius:10,
  borderColor:'#D3D3D3',
  borderStyle:'solid',
  borderWidth:1,
}


const Container = (props:{children:React.ReactNode,label:string}) => {
  return (
    <View style={style} >
      <View style={containerStyle} >
        <>
        <Text style={{fontWeight:'bold'}} >{props.label}</Text>
        <Divider style={{marginVertical:5}} />
        {props.children}
        </>
      </View>
    </View>

  )
}

export default Container;