import { Text, View } from "react-native"

const ListContainer = (props:{children:React.ReactNode,label:string}) => {
  return (
    <View style={{padding:10,backgroundColor:'white',display:'flex',flexDirection:'column'}} >
      <Text>{props.label}</Text>
      {props.children}
    </View>
  )

}

export default ListContainer
