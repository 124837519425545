import { useContext, useEffect, useState } from "react";
import { Image, Text, TouchableOpacity, View } from "react-native";
import { UserContext } from "~/context/UserContext/UserContext";
import { bankItem } from "~/functions/utils/nordigen/endpoints";
import Button from "~/ui/button/Button";
import CloseButton from "~/ui/button/CloseButton";
import SelectMenu from "~/ui/selectmenu/SelectMenu";
import { usePayment } from "~/utils/payment";
import { useBackendFunction } from "~/utils/useBackendFunction";
import aimTransactions from '~/data/bank/aimTransactions.json'
import { convertNordigenToPaymentItem } from "~/utils/convert/nordigen";


export default function AddBankView() {
  const {user} = useContext(UserContext)
  const {data,getData} = useBackendFunction('nordigen-v1_listAllBanks')

  const [isLoading,setIsLoading] = useState(false)
  const [isShowing,setIsShowing] = useState(false)
  const listBanks = async() => {
    setIsLoading(true)
    await getData({})
    setIsShowing(true)
    setIsLoading(false)
  }

  const onClose = () => setIsShowing(false)


  const {getData:getBankLink} = useBackendFunction('nordigen-v1_addBank')
  const addBank = async(id:string) => {
    const response = await getBankLink({
      institution_id:id,
      orgID: user?.data.currentOrganisation,
      schema:'http://localhost:19006'
    })
    console.log(
      response
    )
    window.location.href = response.link
  }
  return (
    <>

    <View>
      <SelectMenu
        label="Bank Toevoegen"
        onPress={() => listBanks()}
        >  <UploadAIMTransactions />

        </SelectMenu>

      {isLoading && <Text>Loading</Text>}

      { isShowing &&
        <View style={{padding:2}} >
          <View style={{display:'flex',flexDirection:'row-reverse',}} >
            <CloseButton
              onPress={onClose}
            />
          </View>

          <View style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}} >
            {data?.map(item => (
              <TouchableOpacity
                onPress={() => addBank(item.id)}
                style={{height:50,width:100,backgroundColor:'gray',display:'flex',alignItems:'center',flexDirection:'row',margin:5,padding:5}} >
                <Image source={{uri:item.logo,width:35,height:35}} />
                <Text style={{marginLeft:5,fontSize:8}} >{item.name}</Text>
              </TouchableOpacity>
            ))}
          </View>

      </View>
      }

      

    </View>
    </>
  )
}


const UploadAIMTransactions = () => {
  const {payments,user} = useContext(UserContext)
  const payment = usePayment()
  const upload = () => {
    const items = aimTransactions.transactions.booked.map(item => convertNordigenToPaymentItem(item))
    for( const item of items){
      payment.update(
        null,
        item
        )
    }
  }
  return <Button
  label="Upload aim files"
  onPress={upload}
  />
}