import { useNavigation } from "@react-navigation/native";
import { Text, Touchable, TouchableOpacity, View } from "react-native";


export default function Header(){
  return (
    <View style={{width:'100%',display:'flex',flexDirection:'row'}}>

      <NavButton
        navTo="home"
        label='Home'
      />

      <NavButton
        navTo="mail"
        label='Mail'
      />

      <NavButton
        navTo="invoices"
        label='invoices'
      />

      <NavButton
        navTo="bank"
        label='bank'
      />
      
    </View>
  )
}


const NavButton = ({label,navTo}:{label:string,navTo:string}) => {
  const navigate = useNavigation()
  return (
    <TouchableOpacity onPress={() => navigate.navigate(navTo)} >
    {label}
    </TouchableOpacity>
  )
}