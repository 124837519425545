import { useContext } from "react";
import { Text } from "react-native";
import { UserContext } from "~/context/UserContext/UserContext";
import Button from "~/ui/button/Button";
import { useBackendFunction } from "~/utils/useBackendFunction";


const GetLiveData = () => {
  const {user} = useContext(UserContext)
  const email = user?.data.email as string
  const callFunction = useBackendFunction('devTools-importUserData',{email})
  const onPress = () => {
    callFunction().then(console.log)
  }
  return ( 
    <Button
      label={`get Live data from user ${email}`}
      onPress={onPress}
    />
  )
}

export default function HomeScreen() {
  return (
    <Text>
      Home Screen
      <GetLiveData />
    </Text>
  )
}