import React from 'react'
import {StyleProp, Text, TextStyle, TouchableOpacity, View, ViewStyle} from 'react-native'

interface ButtonProps{
  onPress: () => void
  label: string
  style?:StyleProp<ViewStyle>
}

const style:StyleProp<ViewStyle> = {
  backgroundColor:'blue',
  alignSelf: 'flex-start',
  borderColor:'#D3D3D3',
  borderStyle:'solid',
  borderWidth:1,
  borderRadius:5,
  paddingHorizontal:10,
  paddingVertical:10,
}


const Button = (props:ButtonProps) => {
  return (
    <TouchableOpacity
      onPress={props.onPress}
      style={[style,props.style]}
      >
      <Text style={{color:'white'}} >{props.label}</Text>
    </TouchableOpacity>
  )
}

export default Button

