import { useContext, useState } from "react";
import { ScrollView, Text, TouchableOpacity, View } from "react-native";
import Divider from "~/components/Misc/Divider";
import { UserContext } from "~/context/UserContext/UserContext";
import { InvoiceType } from "~/types/invoice";
import { PaymentItem } from "~/types/payment";
import Button from "~/ui/button/Button";
import { calculateTotalPriceOfProducts } from "~/utils/document/calculations/index";
import { usePayment } from "~/utils/payment";

// TODO show maximaal 3 open bank transacties


const PaymentItemView = ({invoiceItem,paymentItem}:{invoiceItem:InvoiceType,paymentItem:PaymentItem}) => {
  const [isOpen,setIsOpen] = useState(false)
  const payment = usePayment()
  return (
    <View style={{padding:10}} >
    <TouchableOpacity
      onPress={() => setIsOpen(true)}
      style={{height:75,backgroundColor:'white',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}} >
      <Button
        label="koppelen bevestigen"
        onPress={()=> payment.linkToInvoice(invoiceItem.id,paymentItem.id) }
      />
      <Text>{invoiceItem?.data?.title}</Text>
    </TouchableOpacity>
    {isOpen && (
      <View style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}} >

        <View >
          <Text>Betaling kenmerken</Text>
          <Text>{JSON.stringify(paymentItem,null,2)}</Text>
        </View>

        <View>
          <Text>Factuur</Text>
          <Text>{JSON.stringify(invoiceItem,null,2)}</Text>
        </View>

      </View>
    )}
    <Divider />
    </View>
  )
}

export default function RecommendedPaymentLink(){
  const {invoices,payments} = useContext(UserContext)
  const recommandedPayments:{invoice:InvoiceType,payment:PaymentItem}[] = payments
    .filter(payment => !Boolean(payment.data.linkedInvoiceID))
    .map((payment) => {
      const isMatch:InvoiceType = invoices.find(invoice => calculateTotalPriceOfProducts(invoice.data.products) == payment.data.amount );
      if(isMatch) return {invoice:isMatch,payment};
      return undefined;
    })
    .filter((item) => {return typeof item!='undefined'})
  return (
    <View>
      <Text>Automatische koppeling gevonden {recommandedPayments.length}</Text>
      <ScrollView contentContainerStyle={{height:200}} >
        {recommandedPayments.map(item => (<PaymentItemView invoiceItem={item.invoice} paymentItem={item.payment} />))}
      </ScrollView>
    </View>
  )
}
