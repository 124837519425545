import { DateTime } from "luxon"
import { StyleSheet, Text, View } from "react-native"
import Divider from "~/components/Misc/Divider"
import { documentTypes } from "~/types/document"
import { InvoiceType } from "~/types/invoice"
import { organisationType } from "~/types/organisation"
import { QuotationType } from "~/types/quotation"
import { calculateSubTotalOfProducts, calculateTotalPriceOfProducts, calculateVatOfProducts, calculateVatsOfProducts, ProductType } from "~/utils/document/calculations"

const styles = StyleSheet.create({
  container:{
    backgroundColor:'red',
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-between'
  },
  text:{
    fontSize:6,
    marginVertical:2,
    textAlign:'center'
  }
})

interface Props{
  num:number
  totalNum:number
  products:ProductType[]
  document:InvoiceType | QuotationType
  documentType: documentTypes
  organisation:organisationType
}

const Footer = ({num,totalNum,products,document,organisation,documentType}:Props) => {
  const isLastPage = num === totalNum
  const daysUntilEndDate = (document?.data?.expiration?.length) ? DateTime.fromISO(document.data.expiration).diff(DateTime.fromISO(document.data.date),['days']).days+1 : '-'
  const vats = calculateVatsOfProducts(products)

  return (
    <View style={{flex:1,backgroundColor:'',marginTop:'auto'}} >
    {isLastPage ? 
    <>  
      {document?.data?.note ?
      <View
      style={{
        backgroundColor:'red',maxWidth:150,marginLeft:20,
      marginTop:3}} >
      <Text style={[styles.text,{textAlign:'left',borderRadius:5,padding:5}]} >{document?.data?.note}</Text>
      </View> : null}
    <View style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}} >
      <View style={{display:'flex',justifyContent:'flex-end',flexDirection:'column',marginVertical:5,width:'30%',marginLeft:20}} >
      { (documentType=='Quotations') ? 
      <>
        <View style={{borderBottomColor:'red',borderBottomWidth:1}} >
          <Text style={[styles.text,{textAlign:'left',fontSize:5,maxWidth:100}]} >Naam</Text>
        </View>

        <View style={{borderBottomColor:'red',borderBottomWidth:1}} >
          <Text style={[styles.text,{textAlign:'left',fontSize:5,borderBottomColor:'black',borderBottomWidth:2,maxWidth:100}]} >Handtekening</Text>
        </View>

        <View style={{borderBottomColor:'red',borderBottomWidth:1}} >
          <Text style={[styles.text,{textAlign:'left',fontSize:5,borderBottomColor:'black',borderBottomWidth:2,maxWidth:100}]} >Datum</Text>
        </View>
        </>
        : null}
      </View> 
      <View style={{display:'flex',justifyContent:'flex-end',flexDirection:'row',marginVertical:5}} >
        <View style={{width:100,marginRight:30}} >
          <View style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}} >
            <Text style={[styles.text,{textAlign:'left'}]} >Subtotaal:</Text>
            <Text style={[styles.text,{textAlign:'right'}]} >€ {calculateSubTotalOfProducts(products)}</Text>
          </View>
          <Divider />
          {vats.map(vat =>
          <View key={`vat-${vat.vat}`} style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}} >
            <Text style={[styles.text,{textAlign:'left'}]} >Btw {vat.vat} %:</Text>
            <Text style={[styles.text,{textAlign:'right'}]} >€ {vat.total}</Text>
          </View>)}
          <Divider />
          <View style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}} >
            <Text style={[styles.text,{textAlign:'left'}]} >Totaal:</Text>
            <Text style={[styles.text,{textAlign:'right'}]} >€ {calculateTotalPriceOfProducts(products,true)}</Text>
          </View>
          <Divider />
        </View>
      </View>

    </View>
    </>
    : null }
      <View style={{marginTop:'auto',bottom:15}} >
        {documentType == 'Invoices' ? 
        <View style={{display:'flex',justifyContent:'center',alignItems:'center'}} >
          <Text style={[styles.text,{maxWidth:250,marginHorizontal:'auto'}]} >Gelieve binnen {Number(daysUntilEndDate).toFixed()} dagen te betalen op <Text style={{fontWeight:'bold',fontSize:4}} >{organisation?.data?.iban ?? '{iban}'}</Text> t.n.v <Text style={{fontWeight:'bold',fontSize:4}} >{(organisation?.data?.name?.length) ? organisation?.data?.name : '{naam}'}</Text>  onder vermelding van uw factuurnummer</Text>
        </View> : null}
        <Text style={[styles.text,{color:'blue'}]} >Powered by aimdirect</Text>
        <Text style={{fontSize:6,textAlign:'center'}} >{num}/{totalNum} pagina</Text>
      </View>

    </View>
  )
}

export default Footer