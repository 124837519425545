import { StyleProp, View, ViewStyle } from "react-native"


const style:StyleProp<ViewStyle> = {
  minHeight:'100vh',
  width:'100%',
  paddingTop:100,
  backgroundColor:'#F5F5F5'
}

export default function ScreenView ({children}:{children:React.ReactNode} )  {
  return(
    <View style={style} >
      {children}
    </View>
  )
}