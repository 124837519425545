export interface ProductType {
  name:string
  quantity:number | string
  pricePerUnit:number | string
  pricePerUnitIsWithVat: boolean
  vatPercentage: vatType
}
export type vatType = '21'|'9'|'0' | string | number



export const calculateTotalPriceOfProduct = (product:ProductType,withVat=true) => {
  if(typeof product == 'undefined') return 0;
  if(product == null) return 0;
  if(withVat) return (Number(product.pricePerUnit) * Number(product.quantity) * (Number(product.vatPercentage)/100+1)).toFixed(2)
  return (Number(product.pricePerUnit) * Number(product.quantity)).toFixed(2)
}

export const calculateVatOfProduct = (product:ProductType) => {
  if(typeof product == 'undefined') return 0;
  if(product == null) return 0;
  return (Number(product.pricePerUnit) * Number(product.quantity) * (Number(product.vatPercentage)/100)).toFixed(2)
}

export const calculateVatOfProducts = (products:ProductType[]) => {
  if(typeof products == 'undefined') return 0;
  if(products == null) return 0;
  if(!products?.length) return 0
  return products
    .map(product => (Number(product.pricePerUnit) * Number(product.quantity) * (Number(product.vatPercentage)/100)).toFixed(2))
    .reduce((prev,current) => {
      return Number(Number(prev)+Number(current)).toFixed(2)
    })
}
export const calculateVatsOfProducts = (products:ProductType[]):{vat:vatType,total:string}[] => {
  if(typeof products == 'undefined') return []
  if(products == null) return []
  if(!products?.length) return []
  const vatTypes = products.map(product => (product.vatPercentage))
  const uniqArray = [...new Set(vatTypes)];
  console.log('uniq',uniqArray)
  return uniqArray.map(vat => {
    const matchingVatProducts = products.filter((product) => vat == product.vatPercentage)
    const total = matchingVatProducts
      .map((product) => (Number(product.pricePerUnit) * Number(product.quantity) * (Number(product.vatPercentage)/100)).toFixed(2) )
      .reduce((current,prev) => String(Number(current)+Number(prev)) )
    return ({
      vat: vat,
      total: Number(total).toFixed(2)
    })
  })

  return []
  // return products
  //   .map(product => (Number(product.pricePerUnit) * Number(product.quantity) * (Number(product.vatPercentage)/100)).toFixed(2))
  //   .reduce((prev,current) => {
  //     return Number(Number(prev)+Number(current)).toFixed(2)
  //   })
}

export const calculateTotalPriceOfProducts = (products:ProductType[],withVat=true) => {
  if(typeof products == 'undefined') return 0;
  if(products == null) return 0;
  if(!products?.length) return 0
  return products.reduce((accumulator, element) => {
    return (withVat) ? accumulator + Number(element.pricePerUnit) * Number(element.quantity) * (Number(element.vatPercentage)/100+1) :accumulator + Number(element.pricePerUnit) * Number(element.quantity)
  },0).toFixed(2)
}

export const calculateSubTotalOfProducts = (products:ProductType[]):string => {
  const withVat = calculateTotalPriceOfProducts(products,true)
  const withoutVat = calculateTotalPriceOfProducts(products,false)
  return Number(withoutVat).toFixed(2)

}