import { getFunctions, httpsCallable } from "firebase/functions"
import { useEffect, useState } from "react"
import { Platform } from "react-native"
import { BackendFunctions, FunctionTypeName } from "~/types/backendFunctions"



export const useBackendFunction =  <T extends FunctionTypeName>(name:T):{ data: BackendFunctions<T>['response'] | undefined; getData: (data: BackendFunctions<T>['request']) => Promise<BackendFunctions<T>["response"]>} => {
  // const test:BackendFunctions<'nordigen-v1_addBank'>[''] = 'testing'
  const [return_data,setData] = useState<BackendFunctions<T>['response'] | undefined>(undefined)
  const getData = async (data:BackendFunctions<T>['request']) => {
    return await httpsCallable(getFunctions(),name)(data)
    .catch(() => {
      console.error('Error in useBackendFunction')
      return undefined
    })
    .then(async(results) => {
      setData(results!.data as BackendFunctions<T>['response'])
      return results!.data as BackendFunctions<T>['response']
    })
  }
  useEffect(() => {
    console.log('return data',return_data)
  },[return_data])
  return ({data:return_data,getData})
}


export const useSchema = ():SchemaType => {
  switch(Platform.OS){
    case 'android': return 'http://localhost:19006'
    case 'ios': return 'http://localhost:19006'
    case 'web': {return  'http://localhost:19006'}
    default: {return 'http://localhost:19006'}
  }
  // return 
}
