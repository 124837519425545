import { ArrayPath, Control, Controller, FieldValues, Path } from "react-hook-form";
import { Text, TextInput, View } from "react-native";

import Fuse from 'fuse.js'
import { useContext, useEffect, useState } from "react";
import { PaymentItem } from "~/types/payment";
import { UserContext } from "~/context/UserContext/UserContext";
import { ArrayKey } from "react-hook-form/dist/types/path/common";

import { AntDesign } from '@expo/vector-icons';


export default function SearchInput<S extends any[]>(props: {data: S, dataKeys:any[],setResults:(results:S) => void}) {


  const fuse = new Fuse(props.data, {keys:props.dataKeys });
  const [search,setSearch] = useState('')
  useEffect(() => {
    if(search.length == 0) {
      props.setResults(props.data.splice(0,10))
      return;
    }else{
      props.setResults(
        fuse.search(search).map(items => items.item ).splice(0,10) as S[] 
      )
    }
  },[search])

  return (
    <View
      style={{
        borderRadius:5,
        backgroundColor:'#F0F0F0',
        borderColor:'#121212',
        borderStyle:'solid',
        display:'flex',
        alignItems:'center',
        flexDirection:'row',
        borderWidth:.1,
      }}
    
    >
      <View style={{width:30,display:'flex',justifyContent:'center',alignItems:'center',position:'absolute'}} >

        <AntDesign
          name="search1"
          size={24}
          color="black"
          />
        </View>
    <TextInput
      
      style={{
        paddingLeft:30,
        width:'100%',
        height:35,
        paddingHorizontal:10,
        paddingVertical:5,
        borderRadius:5,
      }}
      value={search}
      onChangeText={setSearch}
      />
    </View>
  )
}