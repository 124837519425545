import React, { useContext } from "react"
import { StyleSheet, Text, View } from "react-native"
import {DateTime} from 'luxon'
import { InvoiceType } from "~/types/invoice"
import { organisationType } from "~/types/organisation"
import { RelationType } from "~/types/relation"
import { documentTypes } from "~/types/document"


const fontSize = 7
const marginVertical = 1


interface Props{
  invoice:InvoiceType,
  organisation:organisationType,
  relation:RelationType,
  documentType: documentTypes
}

const Header = ({invoice,organisation,relation,documentType}:Props) => {
  const color = '#0000FF'
  const styles = StyleSheet.create({
    container:{
      backgroundColor: '#0000FF',
      display:'flex',
      flexDirection:'row',
      justifyContent:'space-between',
      paddingHorizontal:15,
      paddingVertical:10
    },
    text_left:{
      fontSize,
      marginVertical,
      color
    },
    text_right:{
      fontSize,
      color,
      marginVertical,
      textAlign:'right'
    }
  })

  const documentTitle = () => {
    switch(documentType){
      case 'Invoices':
        return 'Factuur'
      case 'Quotations':
        return 'Offerte'
      default: return ''
    }
  }
  const convertDate = (ISOdate:string) => DateTime.fromISO(ISOdate).toFormat(`dd LLL yyyy`)
  return (
    <View>
    <View style={[styles.container]} >
      <View style={{maxWidth:'50%',display:'flex',justifyContent:'space-between'}} >
        <View>
          <Text style={[styles.text_left,{fontWeight:'bold'}]} >{relation?.data?.organisation}</Text>
          <Text style={[styles.text_left,{fontWeight:'bold'}]} >n.a.v. {relation?.data?.name ?? '----'}</Text>
        </View>
        <View>
          <Text style={styles.text_left} >{relation?.data?.address?.street ?? '-'}</Text>
          <Text style={styles.text_left} >{relation?.data?.address?.postal ?? '-'} {relation?.data?.address?.city ?? '-'}</Text>
          <Text style={styles.text_left} >{relation?.data?.phone  ?? '-'}</Text>
          <Text style={styles.text_left} >{relation?.data?.email  ?? '-'}</Text>
          <Text style={styles.text_left} >KVK: {relation?.data?.kvk ?? '-'}</Text>
          <Text style={styles.text_left} >BTW: {relation?.data?.btw ?? '-'}</Text>
        </View>


      </View>
      <View style={{display:'flex',justifyContent:'space-between'}} >
        <View >
          <Text style={[styles.text_right,{fontWeight:'bold'}]} >{organisation?.data?.name ?? '---'}</Text>
          <Text style={styles.text_right} >{organisation?.data?.address?.street ?? '---'}</Text>
          <Text style={styles.text_right} >{organisation?.data?.address?.postal ?? '--'} {organisation?.data?.address?.city ?? '--'}</Text>
        </View>
        <View>

          <Text style={styles.text_right} >{organisation?.data?.phone ?? '---'}</Text>
          <Text style={styles.text_right} >{organisation?.data?.email ?? '---'}</Text>
          <Text style={styles.text_right} >{organisation?.data?.kvk ?? '---'}</Text>
          <Text style={styles.text_right} >{organisation?.data?.btw ?? '---'}</Text>
          <Text style={styles.text_right} >{organisation?.data?.iban ?? '---'}</Text>
        </View>
        {/* STRAATNAAM */}
      </View>
    </View>
    <View style={[styles.container,{justifyContent:'flex-start'}]} >
        <View>
          {/* <Text style={styles.text_left} >{documentTitle()}nummer {invoice?.data?.number ?? '-'} </Text> */}
          {/* <Text style={styles.text_left} >Relatie nummer {relation?.data?.number ?? '-'} </Text> */}
        </View>
        <View style={{marginLeft:10}} >
          {/* <Text style={styles.text_left} >{documentTitle()}datum {convertDate(invoice?.data?.date) ?? '-'} </Text> */}
          {/* <Text style={styles.text_left} >Vervaldatum {convertDate(invoice?.data?.expiration) ?? '-'}  </Text> */}
        </View>
      </View>
    </View>
  )
}

export default Header