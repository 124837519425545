import { collection, DocumentData, getFirestore, onSnapshot, orderBy, query } from "firebase/firestore"
import { useEffect } from "react"
import { useState } from "react"
import { documentType } from "~/types/document"
import { invoiceStatus, InvoiceType } from "~/types/invoice"



export const useInvoices = (orgID:string | undefined) => {
  const [invoices,setInvoices] = useState<InvoiceType[]>([])
  const ref = query(
                collection(getFirestore(),`organisations/${orgID}/Invoices`),
                orderBy('date','desc')
              )
  useEffect(() => {
    if(typeof orgID == 'undefined') return;
    if(orgID.length == 0) return;
    const unsub = onSnapshot(ref,(snapshot) => {
      setInvoices(
        snapshot.docs.map(doc => ({id:doc.id,data:doc.data() as any}))
      )
    })
    return  () => unsub()
  },[orgID])
  return invoices
}


