import { ActivityIndicator, Text, View } from "react-native";


export default function LoadingScreen(){
  return (
    <View
      style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
      <ActivityIndicator  />
    </View>
  )
}