import { StyleSheet, Text, View } from "react-native"
import Divider from "~/components/Misc/Divider"
import { InvoiceType } from "~/types/invoice"
import { QuotationType } from "~/types/quotation"
import { calculateTotalPriceOfProduct, ProductType } from "~/utils/document/calculations/index"


const fontSize = 6
const styles = StyleSheet.create({
  container:{
    marginHorizontal:15,
  },
  product_container_row:{
    display:'flex',
    flexDirection:'row',
    width:'100%',
    height:15,
    marginVertical:10,
    alignItems:'center'
  },
  col1:{
    width:'45%',fontSize
  },
  col2:{
    width:'15%',fontSize
  },
  col3:{
    width:'15%',fontSize
  },
  col4:{
    width:'15%',fontSize
  },
  col5:{
    width:'10%',fontSize
  }

})

interface Props{
  products:ProductType[]
  document:InvoiceType | QuotationType
  key:string
}
const Products = ({products,document,key}:Props) => {
  return(
    <View key={key} style={styles.container} >
      <Text style={{fontSize:8,marginTop:10}} >{document?.data?.title ?? '--'}</Text>
          <View style={{display:'flex',flexDirection:'row',marginTop:5}} >
            <Text style={[styles.col1,{fontWeight:'bold'}]} >Omschrijving</Text>
            <Text style={[styles.col2,{fontWeight:'bold'}]} >Aantal</Text>
            <Text style={[styles.col3,{fontWeight:'bold'}]} >Per stuk</Text>
            <Text style={[styles.col4,{fontWeight:'bold'}]} >Totaal excl</Text>
            <Text style={[styles.col5,{fontWeight:'bold'}]} >BTW</Text>
          </View>

          {
            products.map((product,index) =>
              <>
            <View key={`products-${index}`} style={styles.product_container_row} >
              <Text style={styles.col1} >{product.name}</Text>
              <Text style={styles.col2} >{product.quantity} x</Text>
              <Text style={styles.col3} >€ {product.pricePerUnit}</Text>
              <Text style={styles.col4} >€ {calculateTotalPriceOfProduct(product,false)}</Text>
              <Text style={styles.col5} >{product.vatPercentage} %</Text>
            </View>
            <Divider />
            </>
            )
          }
          </View>
        
        )


}

export default Products