import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth, setPersistence, inMemoryPersistence, Auth } from "firebase/auth";
import {getFirestore, connectFirestoreEmulator} from 'firebase/firestore'
import { initializeFirestore } from "firebase/firestore";
import Constants from "expo-constants";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectStorageEmulator, getStorage } from 'firebase/storage'


const useFirebase = () => {
  // FIREBASE BETA AIMDIRECT CONFIG
  const firebaseConfig = {
    apiKey: "AIzaSyB2XOP0a23mYzS8IUyKXMsxr5f2k4s6qsc",
    authDomain: "betaaimdirect.firebaseapp.com",
    projectId: "betaaimdirect",
    storageBucket: "betaaimdirect.appspot.com",
    messagingSenderId: "827070068397",
    appId: "1:827070068397:web:4c6949790b61c471da7824"
  }
  // FIREBASE AIMAPP CONFIG
  // const firebaseConfig = {
  //   apiKey: "AIzaSyAz7dNipgblNueJNDWe9E0_joyuF_I88WY",
  //   authDomain: "appaimdirect.firebaseapp.com",
  //   projectId: "appaimdirect",
  //   storageBucket: "appaimdirect.appspot.com",
  //   messagingSenderId: "457049065017",
  //   appId: "1:457049065017:web:32d9ff72eb3a40ed9aefe8",
  //   measurementId: "G-J85WRB4MX0"
  // };


const app = initializeApp(firebaseConfig);


const origin = Constants!.manifest!.debuggerHost?.split(":").shift() || "localhost";
initializeFirestore(app, {
  ignoreUndefinedProperties: true,
});
if(__DEV__) {
  console.log('connecting to emulators')
  // connectStorageEmulator(getStorage(),origin,)
  connectFunctionsEmulator(getFunctions(),origin,5001)
  connectFirestoreEmulator(getFirestore(),'localhost',8080)
  connectAuthEmulator(getAuth(), `http://${origin}:9099`, { disableWarnings: false })
}

}

export {useFirebase}


