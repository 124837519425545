import { getAuth, onAuthStateChanged, User } from "firebase/auth"
import { doc, getFirestore, onSnapshot } from "firebase/firestore"
import { useEffect, useState } from "react"
import { organisationType } from "~/types/organisation"
import { userType } from "~/types/user"

export const useOrganisation = (orgID:string  | undefined):organisationType | undefined => {
  const [organisation,setOrganisation] = useState<organisationType | undefined>(undefined)

  useEffect(() => {
    if(typeof orgID == 'undefined' )return;
    const unsub = onSnapshot(
      doc(getFirestore(),`organisations/${orgID}`),(snapshot) => {
        setOrganisation({id:snapshot.id,data:snapshot.data()} as organisationType)
    })
    return () => unsub()
  },[orgID])
  return organisation
}
