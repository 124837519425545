import { useContext, useEffect, useState } from "react"
import { FlatList, Text, TouchableOpacity, View } from "react-native"
import Divider from "~/components/Misc/Divider"
import { UserContext } from "~/context/UserContext/UserContext"
import { InvoiceType } from "~/types/invoice"
import SearchInput from "~/ui/input/SearchInput"
import StatusLabel from "~/ui/status/StatusLabel"
import { calculateTotalPriceOfProducts } from "~/utils/document/calculations/index"

import { AntDesign } from '@expo/vector-icons';

export default function PaymentItemView (props:{item:PaymentItem})  {
  const [isOpen,setIsOpen] = useState(false)
  const {payments,invoices} = useContext(UserContext)

  const [results,setResults] = useState<InvoiceType[]>(() => invoices.filter(invoice => !invoice.data.linkedTransactionID  ).splice(0,4))

  return (
    <>
    <TouchableOpacity
    onPress={() => setIsOpen(!isOpen)}
    style={{display:'flex',flexDirection:'row',justifyContent:'space-between',padding:5,height:75,alignItems:'center'}} >
    
    <View style={{display:'flex',flexDirection:'row',alignItems:'center'}} >

      <View style={{display:'flex'}} >
        <StatusLabel color='green' label="Gekoppeld" />
      </View>

      <View style={{display:'flex',marginLeft:10}} >
        <Text style={{fontWeight:'bold',fontSize:15}} >{props?.item?.data?.name}</Text>
      </View>
    </View>



    <View>
      <Text>{props?.item?.data?.amount}</Text>
    </View>

    </TouchableOpacity>
    {isOpen && 
    <View style={{
      height:250,
      position:'relative',
      backgroundColor:'#F5F5F5',
      padding:10,
      borderRadius:10
      }} >
        <SearchInput
          data={invoices}
          dataKeys={['data.title']}
          setResults={setResults}
        />

        <FlatList
          style={{height:'90%',overflow:'scroll'}}
          data={results}
          renderItem={({item}) => (
            <View style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',height:35,paddingHorizontal:10}} >
              <Text>{item.data.title}</Text>
              <Text>{calculateTotalPriceOfProducts(item.data.products)}</Text>
            </View>
            )
          }
        
        />



    </View>
    }
    <Divider />
    </>
  )
}