import { StyleProp, Text, View, ViewStyle } from "react-native";


const style:StyleProp<ViewStyle> = {
  paddingVertical:8,
  paddingHorizontal:10,
  backgroundColor:'green',
  borderRadius:5,
  width:100,
  display:'flex',
  justifyContent:'center',
  alignItems:'center'
}

interface Props{
  label:string
}

export default function(props: Props) {
  return (
    <View style={style} >
      <Text style={{color:'white'}} >
        {props.label}
      </Text>
    </View>
  )
}