import { collection, DocumentData, getFirestore, onSnapshot } from "firebase/firestore"
import { useEffect } from "react"
import { useState } from "react"
import { PaymentItem } from "~/types/payment"
import { documentType } from "~/types/document"
import { invoiceStatus } from "~/types/invoice"

import * as BankData from './../../../data/bank/aimTransactions.json';

export const usePayments = (orgID:string | undefined) => {
  const [payments,setPayments] = useState<PaymentItem[]>([])
  
  // const payments = BankData.transactions.booked
  //                         .map(transaction => ({
  //                           id:'123',
  //                           data:{
  //                             name: transaction?.creditorName ?? transaction?.debtorName ?? transaction?.remittanceInformationUnstructured,
  //                             iban: transaction?.creditorAccount?.iban ?? transaction?.debtorAccount?.iban ?? '-',
  //                             amount:transaction.transactionAmount.amount,
  //                           }
  //                           } as PaymentItem
  //                         ))
  // const ref = collection(getFirestore(),`organisations/${orgID}/Banks`)

  // const [banksIDs,setBanksIDs] = useState<string[]>([])
  // useEffect(() => {
  //   if(typeof orgID == 'undefined') return;
  //   if(orgID.length == 0) return;
  //   const unsub = onSnapshot(ref,(snapshot) => {
  //     setBanksIDs(snapshot.docs.map(doc => doc.id))
  //   })
  //   return  () => unsub()
  // },[orgID])
  useEffect(() => {
    if(typeof orgID == 'undefined') return;
    if(orgID.length == 0) return;
    const localRef = collection(getFirestore(),`organisations/${orgID}/payments`)
    const unsub = onSnapshot(localRef,(snapshot) => {
      console.log('payments',snapshot.docs.map(doc => ({id:doc.id,data:doc.data() } as any)))
      setPayments(
        snapshot.docs.map(doc => ({id:doc.id,data:doc.data() } as any))
      )
    })
    return  () => unsub()
  },[orgID])
  return payments
}

