import { addDoc, collection, doc, Firestore, getFirestore, setDoc } from "firebase/firestore"
import { cloudbilling } from "googleapis/build/src/apis/cloudbilling/index"
import { useContext } from "react"
import { UserContext } from "~/context/UserContext/UserContext"
import { InvoiceType } from "~/types/invoice"
import { PaymentItem } from "~/types/payment"



export const usePayment = () => {
  const {user} = useContext(UserContext)
  const orgID = user?.data.currentOrganisation
  return {
    linkToInvoice: async (invoiceID: string, paymentID: string) => {
      await setDoc(
        doc(getFirestore(),`organisations/${user?.data.currentOrganisation}/payments/${paymentID}`),
            {linkedInvoiceID:invoiceID},
            {merge:true}
      )
      await setDoc(
        doc(getFirestore(),`organisations/Invoices/${invoiceID}`),
            {linkedPaymentID:paymentID},
            {merge:true}
      )
    },
    update: async(paymentID:string | null,data:Partial<PaymentItem['data']>) => {
      if(paymentID == null) {
        await addDoc(
          collection(getFirestore(),`organisations/${orgID}/payments`),
              data,
          )
      }
      else {
        await setDoc(
          doc(getFirestore(),`organisations/${orgID}/payments/${paymentID}`),
              data,
              {merge:true}
          )
        }
    },
  }

}