import { getFirestore } from "firebase/firestore";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { FlatList, LayoutChangeEvent, Text, TouchableOpacity, View } from "react-native";
import {convertNordigenToPaymentItem} from '~/utils/convert/nordigen'
// TODO show bank cash over last 6 months
// TODO group payments
// TODO show recurring payments


import Svg, { Polyline } from 'react-native-svg';
import Container from "~/components/Misc/Container";
import Divider from "~/components/Misc/Divider";
import ListContainer from "~/components/Misc/ListContainer";
import { UserContext } from "~/context/UserContext/UserContext";
import { PaymentItem } from "~/types/payment";
import { InvoiceType } from "~/types/invoice";
import Button from "~/ui/button/Button";
import Input from "~/ui/input/Input";
import SearchInput from "~/ui/input/SearchInput";
import { calculateTotalPriceOfProducts } from "~/utils/document/calculations/index";
import { invoice } from "~/utils/invoice/index";
import { usePayment } from "~/utils/payment";
import { useBackendFunction, useSchema } from "~/utils/useBackendFunction";


import AddBankView from "~/components/Bank/AddBankView";
import RecommendedPaymentLink from "~/components/Bank/RecommendedPaymentLink";
import PaymentItemView from "~/components/Payment/PaymentItemView";
import ScreenView from "~/ui/layout/ScreenView";
import Box from "~/ui/containers/Box";




export default function BankScreen(){
  const {getData:updateTransactions} = useBackendFunction('nordigen-v1_updateTransactions')
  const {payments,user} = useContext(UserContext)
  const unregiseredCash = '€ ' +payments.filter(payment => !Boolean(payment.data.linkedInvoiceID)).reduce((prev,current) => {return prev+Number(current.data.amount)},0) + ' Openstaand'
  return (
    <ScreenView>
    <Container
      label="Bank"
    >
        <View style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}} >

        
          <Button
          label="Update transactions"
          onPress={() => updateTransactions({orgID:user?.data.currentOrganisation})}
          />

          <AddBankView />
        </View>
        <Divider style={{marginVertical:10}} />
      </Container>


    <Container
      label='Automatische koppelingen'
    >

        <RecommendedPaymentLink />

    </Container> 

    <Container>
    <Box>
          <Text>Gekoppelde transacties: {payments.filter(payment => Boolean(payment.data.linkedInvoiceID)).length}</Text>
          <Text>niet gekoppelende transacties: {payments.filter(payment => !Boolean(payment.data.linkedInvoiceID)).length}</Text>
        </Box>
        <FlatList
          data={payments}
          renderItem={({item}) => <PaymentItemView item={item} /> }
        />
    </Container>
    </ScreenView>

  )
}





const RecommendedMatch = ({item}:{item:PaymentItem}) => {
  const {payments,invoices, user} = useContext(UserContext)
  const matchedItem = invoices.find((invoiceItem) => calculateTotalPriceOfProducts(invoiceItem?.data?.products) == item.data.amount )

  const payment = usePayment()
  const linkTransaction = () => {
    payment.linkToInvoice(matchedItem.id,item.id) 
  }
  return (
    <>
    <View style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',height:35,paddingHorizontal:10}} >
      
      <Button
        onPress={linkTransaction}
        label="Koppelen"
      />

      <Text>Aanbevolen match</Text>
      <Text>{matchedItem?.data?.title}</Text>
    </View>
    <Divider />
    </>
  )
}










// const CashChart = () => {
//   const [width,setWidth] = useState(0)
//   const [height,setHeight] = useState(0)
//   const onLayout=(event:LayoutChangeEvent)=> {
//     setWidth(event.nativeEvent.layout.width)
//     setHeight(event.nativeEvent.layout.width/2)
//   }
//   return (
//     <View onLayout={onLayout} >
//       <View style={{backgroundColor:'red',display:'flex'}} >
//       <Text>CashChart</Text>
//       <Svg
//         height={height}
//         width={width}
//         style={{backgroundColor:'white'}}
//         >
//         <Polyline
//         // points=x,y,x,y,x,y
//         points="0,10 20,12 30,20 40,60 60,70 200,90"
//         fill="none"
//         stroke="black"
//         strokeWidth="3"
//         />
//       </Svg>
//       </View>

//     </View>
//   )
// }